// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      :host {
        formly-custom-select {
                width: 100%;
              }
          }
        .custom-ion-alert{
            .alert-checkbox-label{
              color: var(--ion-text-color) !important;
            }
        }
      
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZvcm1seS1zZWxlY3QudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtNQUNNO1FBQ0U7Z0JBQ1EsV0FBVztjQUNiO1VBQ0o7UUFDRjtZQUNJO2NBQ0UsdUNBQXVDO1lBQ3pDO1FBQ0oiLCJmaWxlIjoiZm9ybWx5LXNlbGVjdC50cyIsInNvdXJjZXNDb250ZW50IjpbIlxuICAgICAgOmhvc3Qge1xuICAgICAgICBmb3JtbHktY3VzdG9tLXNlbGVjdCB7XG4gICAgICAgICAgICAgICAgd2lkdGg6IDEwMCU7XG4gICAgICAgICAgICAgIH1cbiAgICAgICAgICB9XG4gICAgICAgIC5jdXN0b20taW9uLWFsZXJ0e1xuICAgICAgICAgICAgLmFsZXJ0LWNoZWNrYm94LWxhYmVse1xuICAgICAgICAgICAgICBjb2xvcjogdmFyKC0taW9uLXRleHQtY29sb3IpICFpbXBvcnRhbnQ7XG4gICAgICAgICAgICB9XG4gICAgICAgIH1cbiAgICAgICJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/formly/formly-select/formly-select.ts"],"names":[],"mappings":";MACM;QACE;gBACQ,WAAW;cACb;UACJ;QACF;YACI;cACE,uCAAuC;YACzC;QACJ;;AAER,ooBAAooB","sourcesContent":["\n      :host {\n        formly-custom-select {\n                width: 100%;\n              }\n          }\n        .custom-ion-alert{\n            .alert-checkbox-label{\n              color: var(--ion-text-color) !important;\n            }\n        }\n      "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
