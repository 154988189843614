// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        .ion-col-with-left-and-right-icon {
            display: flex;
            justify-content: center;

            ion-text {
                padding-left: 1%;
                padding-right: 1%;
                align-self: center;
            }
        }
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInB1bGwtdG8tcmVmcmVzaC50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO1FBQ1E7WUFDSSxhQUFhO1lBQ2IsdUJBQXVCOztZQUV2QjtnQkFDSSxnQkFBZ0I7Z0JBQ2hCLGlCQUFpQjtnQkFDakIsa0JBQWtCO1lBQ3RCO1FBQ0oiLCJmaWxlIjoicHVsbC10by1yZWZyZXNoLnRzIiwic291cmNlc0NvbnRlbnQiOlsiXG4gICAgICAgIC5pb24tY29sLXdpdGgtbGVmdC1hbmQtcmlnaHQtaWNvbiB7XG4gICAgICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICAgICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5cbiAgICAgICAgICAgIGlvbi10ZXh0IHtcbiAgICAgICAgICAgICAgICBwYWRkaW5nLWxlZnQ6IDElO1xuICAgICAgICAgICAgICAgIHBhZGRpbmctcmlnaHQ6IDElO1xuICAgICAgICAgICAgICAgIGFsaWduLXNlbGY6IGNlbnRlcjtcbiAgICAgICAgICAgIH1cbiAgICAgICAgfVxuICAgICJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/pull-to-refresh/pull-to-refresh.ts"],"names":[],"mappings":";QACQ;YACI,aAAa;YACb,uBAAuB;;YAEvB;gBACI,gBAAgB;gBAChB,iBAAiB;gBACjB,kBAAkB;YACtB;QACJ;;AAER,4rBAA4rB","sourcesContent":["\n        .ion-col-with-left-and-right-icon {\n            display: flex;\n            justify-content: center;\n\n            ion-text {\n                padding-left: 1%;\n                padding-right: 1%;\n                align-self: center;\n            }\n        }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
