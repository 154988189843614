// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            formly-field-ion-toggle, formly-field-ion-checkbox, formly-custom-select,
            formly-input-serial-number {
                width: 100%;
            }
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZvcm0tZmllbGQud3JhcHBlci50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO1lBQ1k7O2dCQUVJLFdBQVc7WUFDZiIsImZpbGUiOiJmb3JtLWZpZWxkLndyYXBwZXIudHMiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgICAgICAgICAgIGZvcm1seS1maWVsZC1pb24tdG9nZ2xlLCBmb3JtbHktZmllbGQtaW9uLWNoZWNrYm94LCBmb3JtbHktY3VzdG9tLXNlbGVjdCxcbiAgICAgICAgICAgIGZvcm1seS1pbnB1dC1zZXJpYWwtbnVtYmVyIHtcbiAgICAgICAgICAgICAgICB3aWR0aDogMTAwJTtcbiAgICAgICAgICAgIH1cbiAgICAiXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/formly/form-field.wrapper.ts"],"names":[],"mappings":";YACY;;gBAEI,WAAW;YACf;;AAEZ,4eAA4e","sourcesContent":["\n            formly-field-ion-toggle, formly-field-ion-checkbox, formly-custom-select,\n            formly-input-serial-number {\n                width: 100%;\n            }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
