// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            ion-item > ion-label > h3 {
                font-weight: bolder;
            }
        
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm9mZmxpbmUuY29tcG9uZW50LnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7WUFDWTtnQkFDSSxtQkFBbUI7WUFDdkIiLCJmaWxlIjoib2ZmbGluZS5jb21wb25lbnQudHMiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgICAgICAgICAgIGlvbi1pdGVtID4gaW9uLWxhYmVsID4gaDMge1xuICAgICAgICAgICAgICAgIGZvbnQtd2VpZ2h0OiBib2xkZXI7XG4gICAgICAgICAgICB9XG4gICAgICAgICJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/edge/offline/offline.component.ts"],"names":[],"mappings":";YACY;gBACI,mBAAmB;YACvB;;AAEZ,wYAAwY","sourcesContent":["\n            ion-item > ion-label > h3 {\n                font-weight: bolder;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
