// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .modal-wrapper{
    transform: translate3d(0px, 40px, 0px);
    opacity: 0.9;
    border-radius: 10em !important;
  }
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRoZW1lLXNlbGVjdGlvbi1wb3BvdmVyLnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7RUFDRTtJQUNFLHNDQUFzQztJQUN0QyxZQUFZO0lBQ1osOEJBQThCO0VBQ2hDIiwiZmlsZSI6InRoZW1lLXNlbGVjdGlvbi1wb3BvdmVyLnRzIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIC5tb2RhbC13cmFwcGVye1xuICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlM2QoMHB4LCA0MHB4LCAwcHgpO1xuICAgIG9wYWNpdHk6IDAuOTtcbiAgICBib3JkZXItcmFkaXVzOiAxMGVtICFpbXBvcnRhbnQ7XG4gIH1cbiAgICAiXX0= */`, "",{"version":3,"sources":["webpack://./src/app/user/theme-selection-popup/theme-selection-popover.ts"],"names":[],"mappings":";EACE;IACE,sCAAsC;IACtC,YAAY;IACZ,8BAA8B;EAChC;;AAEF,wdAAwd","sourcesContent":["\n  .modal-wrapper{\n    transform: translate3d(0px, 40px, 0px);\n    opacity: 0.9;\n    border-radius: 10em !important;\n  }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
